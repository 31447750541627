// Variable overrides
$sidebar-compact-width:               98px !default;

$component-background: #2b2c33;
$body-background: #303030;

//$body-bg:       #f3f3f3 !default;
//$body-color:    #ffff00 !default;
$theme-colors: (
  "primary": #517fe2,
//   "info": #ff0000,
  "danger": #ff5757,
  "custom-color": #900
);

$warning-gradient: (
  "start":  #FDD459,  //   ffd256,  
  "stop":   #F5BF1C   //   f4bf1d   
);

$info-gradient: (
  "start":  #0EAFD5,
  "stop":   #0C9DBF
);

$primary-gradient: (
  "start":  #3F50A1,
  "stop":   #3C4C99
);

$danger-gradient: (
  "start":  #ff5757,
  "stop":   #E54E4E
);

$success-gradient: (
  "start":  #5EC298,   //  5ec198,    
  "stop":   #4FA581    //  48b0a8     
);

$secondary-gradient: (
  "start":  #5280E3,   //  5ec198,    
  "stop":   #4973CC    //  48b0a8     
);

$light-gradient: (
  "start":  #E7E9F0,   //  5ec198,    
  "stop":   #E7E9F0    //  48b0a8     
);

$dark-gradient: (
  "start":  #929EBB,   //  5ec198,    
  "stop":   #929EBB    //  48b0a8     
);