// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

// temp fixes
@import "fixes";


.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: #0c2668;
  //background: rgba(131, 43, 127, 0.2);
}

